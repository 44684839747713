/*--------------------- Navbar -------------------*/
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292c53; /* Match navbar color with body color */
  padding: 15px; /* Increased padding for better layout */
  border-radius: 5px; /* Rounded corners for the navbar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.navbar h6 {
  font-weight: bold; /* Heavy font weight */
  font-size: 1.5rem; /* Larger font size */
  text-transform: uppercase; /* Uppercase text for a header-like appearance */
  margin: 0; /* Remove default margin */
  color: #F0F1FF; /* Font color */
}

/* Button Container */
.navbar .button-container {
  display: flex;
  gap: 15px; /* Add consistent spacing between buttons */
}

/* Buttons in Navbar */
.navbar button {
  color: #ffffff; /* Button text color */
  text-transform: none; /* Prevent all-uppercase styling */
  font-size: 1rem; /* Slightly larger font for better readability */
  padding: 10px 20px; /* Increased padding for larger buttons */
  border: 1px solid transparent; /* Invisible border for consistent sizing */
  background: transparent; /* Transparent background */
  transition: all 0.3s ease; /* Smooth transitions */
  border-radius: 5px; /* Rounded corners */
}

/* Hover Effect for Buttons */
.navbar button:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Subtle white overlay on hover */
  color: #ffd700; /* Highlight text color on hover */
  cursor: pointer; /* Pointer cursor */
  border-color: #ffd700; /* Highlight border */
}

/* Icon Buttons (Logout) */
.navbar .MuiIconButton-root {
  color: #ffffff; /* Default icon color */
  border-radius: 5px; /* Rounded icon button */
  transition: all 0.3s ease; /* Smooth transitions */
  padding: 10px; /* Add padding for better interaction */
}

.navbar .MuiIconButton-root:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Subtle overlay on hover */
  color: #ffd700; /* Highlight color on hover */
  cursor: pointer; /* Pointer cursor */
}

/* Active Button State */
.navbar button.active {
  background-color: #ffd700; /* Active button background */
  color: #292c53; /* Contrast text color */
  border: 1px solid #ffd700; /* Highlighted border */
}

/* Button Container for Other Pages */
.button-container {
  display: flex;
  gap: 15px; /* Add spacing between buttons */
  flex-wrap: wrap; /* Wrap buttons if they overflow */
  margin-top: 20px; /* Add spacing above the container */
}

/* Buttons Inside the Container */
.button-container .btn {
  padding: 10px 20px; /* Add padding for larger buttons */
  background-color: #292c53; /* Button background color */
  color: #F0F1FF; /* Button text color */
  border: 2px solid transparent; /* Transparent border */
  border-radius: 5px; /* Rounded corners for buttons */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

.button-container .btn:hover {
  background-color: #4a4e8e; /* Darker background on hover */
  color: #FFFFFF; /* Change text color on hover */
  cursor: pointer; /* Pointer cursor */
}